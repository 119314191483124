import React, { useState, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import { useViewportScroll } from 'framer-motion'
import { Link as LinkScroll, scroller } from 'react-scroll'

// Hooks
import useScrollDirection from '../hooks/useScrollDirection'

// Components
import Layout from '../components/layout'
import SEO from '../components/seo'
import Button from '../components/button'

// Assets
import ArrowDown from '../images/arrow-down.inline.svg'
import Pattern from '../images/tuya-pattern-menu.inline.svg'
import TitlePattern from '../images/title-pattern.inline.svg'

// Styles
import '../styles/pages/food.scss'

// const NavItem = ({ isPassedNav }) => {}
function scrollTo(elementId, offset) {
  scroller.scrollTo(elementId, {
    duration: 800,
    delay: 0,
    smooth: 'easeInOutQuart',
    offset,
  })
}

const Nav = ({ categories, currentCategoryId, setCurrentCategoryId }) => {
  const [isPassedNav, setIsPassedNav] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const scrollDirection = useScrollDirection()
  const { scrollY } = useViewportScroll()
  useEffect(
    () =>
      scrollY.onChange(v => {
        setIsPassedNav(v > 350)
      }),
    [scrollY],
  )
  const currentCategory = categories.find(category => category.id === currentCategoryId)
  return (
    <ul
      style={{
        top: scrollDirection === 'down' ? 0 : 111,
        letterSpacing: '1px',
      }}
      className="bg-grey-200 px-6 py-10 sticky transition-all text-tuya-slate uppercase text-xs font-semibold space-y-9 md:hidden z-20"
    >
      {isPassedNav && !isOpen ? (
        <li>
          <button
            onClick={() => setIsOpen(true)}
            style={{ letterSpacing: 'inherit' }}
            className="flex items-center uppercase font-semibold w-full"
          >
            <ArrowDown className="mr-4" />
            {currentCategory.title}
          </button>
        </li>
      ) : (
        categories.map((category, i) => (
          <li className="px-7" key={category.id}>
            <a
              onClick={() => {
                setIsOpen(false)
                setCurrentCategoryId(category.id)
                scrollTo(category.slug, -220)
              }}
              href={`#${category.slug}`}
            >
              {category.title}
            </a>
          </li>
        ))
      )}
    </ul>
  )
}

const NavDesktop = ({ categories, currentCategoryId, setCurrentCategoryId }) => {
  return (
    <div className="absolute h-full top-14 right-0 nav-wrapper hidden md:block z-20">
      <div className="sticky top-14">
        <ul className=" bg-grey-200 space-y-4 pl-8 py-12 mb-32">
          {categories.map((category, i) => (
            <li
              style={{ letterSpacing: 0.83 }}
              className={`text-xxs font-semibold uppercase ${
                category.id === currentCategoryId ? 'text-tuya-slate' : 'text-grey-100'
              }`}
              key={category.id}
            >
              <LinkScroll
                to={category.slug}
                spy={true}
                smooth={true}
                duration={500}
                hashSpy
                className="cursor-pointer"
                onSetActive={to => setCurrentCategoryId(category.id)}
              >
                {category.title}
              </LinkScroll>
            </li>
          ))}
        </ul>
        <div className="h-full w-full reservation flex justify-end items-center lg:justify-center ">
          <Link to="/reservation">
            <Button isDark isVertical>
              RESERVATION
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

const Section = ({ category: { slug, items, title: categoryTitle } }) => {
  const menuItems = JSON.parse(items)

  return (
    <section className="relative md:mt-20" id={slug}>
      <TitlePattern className="absolute hidden md:block top-2 md:-left-36 lg:-left-28 2xl:-left-12" />
      <div className="menu-container">
        <h2 className="category-title">{categoryTitle}</h2>
        <ul className="category-list">
          {menuItems.map(
            ({ id, title, subtitle, price, textBetweenBrackets, addon, addon_price }) => (
              <li key={id}>
                <h3 className="title">
                  <span>{title}</span>
                  {/*{textBetweenBrackets && <em className='text-xxs'>{textBetweenBrackets}</em>}*/}
                </h3>
                <div className="price">{price}</div>
                {subtitle && (
                  <div className="subtitle">
                    {subtitle}
                    {addon && (
                      <div className="addon">
                        {addon}

                        <span className="ml-4">{addon_price}</span>
                      </div>
                    )}
                  </div>
                )}
              </li>
            ),
          )}
        </ul>
      </div>
    </section>
  )
}
const HeroSection = ({ image }) => {
  return (
    <section className="bg-black hero-section relative">
      <div className="img-wrapper w-full h-full absolute top-0 left-0 z-1">
        <img
          className="w-full h-full object-cover opacity-50"
          src={image.sizes.src}
          srcSet={image.sizes.srcSet}
          alt="Food Menu"
        />
      </div>
      <div className="z-10 relative px-8 md:px-0 pb-11 md:max-w-2xl  lg:max-w-screen-lg md:mx-auto h-full flex items-end">
        <div>
          <Pattern className="text-tuya-yellow mb-3" />
          <h1 className="text-white">Food Menu</h1>
        </div>
      </div>
    </section>
  )
}

const FoodPage = ({ data, location }) => {
  const { categories: categoriesData, heroImage } = data.datoCmsFood
  const [categories, setCategories] = useState([])
  const [currentCategoryId, setCurrentCategoryId] = useState(null)
  useEffect(() => {
    categoriesData.map(
      category => (category.slug = category.title.toLowerCase().split(' ').join('-')),
    )
    setCategories(categoriesData)
    setCurrentCategoryId(categoriesData[0].id)
  }, [categoriesData])

  if (!categories.length) return <></>
  return (
    <Layout className="food-page" pathname={location.pathname}>
      <SEO title="Food" />
      <HeroSection image={heroImage} />
      <div className="relative">
        <NavDesktop
          categories={categories}
          currentCategoryId={currentCategoryId}
          setCurrentCategoryId={setCurrentCategoryId}
        />
        <Nav
          categories={categories}
          currentCategoryId={currentCategoryId}
          setCurrentCategoryId={setCurrentCategoryId}
        />
        {categories.map(category => (
          <Section key={category.id} category={category} />
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query FoodQuery {
    datoCmsFood {
      heroImage {
        sizes {
          srcSet
          src
        }
      }
      categories {
        title
        id
        items
      }
    }
  }
`

export default FoodPage
